* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

html,
body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.main-bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.part1,
.part2,
.part3,
.part4,
.part5 {

    min-height: 906px;
    width: 100%;
    position: relative;
    padding: 3rem 0;
}

.part1 {


    background: #4285F4;
    /* background-image: url(./images/bg2.png); */
    background-image: url(./../../assets/aboutus/bg2.png);
    background-size: cover;
    height: 100vh;


}

.part2 {


    background: #EA4335;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;


}

.part2 .img1 {
    /* background-image: url(images/img1.png); */
    background-image: url(./../../assets/aboutus/img1.png);
    height: 479px;
    width: 479px;
    background-repeat: no-repeat;

}

.part3 {

    background: #FBBC04;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

}

.part3 .img2 {

    /* background-image: url(images/img2.png); */
    background-image: url(./../../assets/aboutus/img2.png);
    height: 479px;
    width: 479px;
    background-repeat: no-repeat;

}

.part4 {

    background: #0F9D58;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */


}

.part4 .container .main-text {
    display: flex;
    flex-wrap: wrap;
}

.part4 .container {

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    /* background-color: aqua; */
}

.part4 .img3 {

    /* background-image: url(images/img3.png); */
    background-image: url(./../../assets/aboutus/img3.png);
    height: 479px;
    width: 500px;
    background-repeat: no-repeat;

}


.part5 {

    background-color: #25272B;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 110%;
}




/* ******************************************************************************************* */

.bc1,
.bc2,
.bc3,
.bc4 {

    display: flex;
    width: 100%;
    height: 89px;
    flex-wrap: wrap;
    overflow: hidden;
}


.bc1 .triangle2 {

    width: 100%;
    height: 100%;
    border-left: 1550px Solid transparent;
    border-bottom: 140px solid #C50000;

}



.bc2 .triangle2 {

    width: 100%;
    height: 100%;
    border-right: 1550px Solid #EA4335;
    border-bottom: 140px solid #C50000;


}

.bc3 .triangle2 {

    width: 100%;
    height: 100%;
    border-left: 1550px Solid #FBBC04;
    border-bottom: 140px solid #F9AB00;

}

.bc4 .triangle2 {

    width: 100%;
    height: 100%;
    border-right: 1550px Solid #0F9D58;
    border-bottom: 140px solid #023B12;


}



/* *************TEXTS******************* */

.part1 .main-text {

    position: absolute;
    color: #ffffff;
    width: 638px;
    height: 351px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    flex-wrap: wrap;
    top: 20%;
    left: 100px;

}

.part1 .main-text h1 {

    font-size: 180px;
    font-weight: 500;
    /* line-height: 218.34px; */


}

.part1 .main-text h3 {

    font-weight: 500;
    font-size: 30px;
    line-height: 36.39px;
    text-align: center;
}

.part2 .main-text {

    color: #ffffff;
    width: 876px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    margin-left: 4rem;
}

.part2 .main-text h1 {

    font-size: 100px;
    font-weight: 750;
    line-height: 121.3px;
    margin-bottom: 4rem;
}

.part2 .main-text p {

    font-size: 40px;
    line-height: 48.52px;
    font-weight: 400;
}



.part3 .main-text {

    color: #000000;
    width: 810px;

}

.part3 .main-text p {

    font-weight: 400;
    font-size: 50px;
    line-height: 60.65px;
}


.part4 .main-text {

    width: 810px;
    color: #ffffff;
    margin: 0 3rem;
}

.part4 .main-text p {

    font-size: 50px;
    font-weight: 400;
    line-height: 60.65px;
}

.part4 h1 {

    font-size: 60px;
    font-weight: 600;
    line-height: 72px;
    margin-bottom: 3rem;
}


/* *************************** DROP DOWN MENU ******************************* */


.part5 {
    height: 1;
}

.part5 .dropbtn {
    background-color: #ffffff;
    width: 485px;
    height: 100px;
    color: #000000;
    font-size: 60px;
    font-weight: 550;
    padding: 16px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    margin-top: -10rem;
}

.part5 .dropdown {
    position: relative;
    display: inline-block;

}

.part5 .dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
    min-width: 100%;
}

.dropdown-content a {
    color: black;
    padding: 16px;
    text-decoration: none;
    font-size: 45px;
    font-weight: 550;
    width: 80%;
    margin-bottom: 1.5rem;
    display: block;
    border-radius: 30px;
    text-align: center;
}

.dropdown-content a:nth-child(1) {
    background-color: #518FFF;
    margin-top: 1.5rem;
}

.dropdown-content a:nth-child(2) {
    background-color: #FF3814;
}

.dropdown-content a:nth-child(3) {
    background-color: #FFCC29;
}

.dropdown-content a:nth-child(4) {
    background-color: #00AA5E;
}



.dropdown-content a:hover {
    background-color: #f1f1f1
}

.dropdown:hover .dropdown-content {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.dropdown:hover .dropbtn {
    background-color: #f1f1f1;
}



/* ***** RESPONSIVENESS ****** */

@media(max-width:1290px) {



    .part2 .main-text,
    .part3 .main-text {

        align-items: center;
        margin: 2rem 0;
    }

    .part2 .main-text p,
    .part3 .main-text p,
    .part4 .main-text p {
        text-align: justify;
        font-size: 35px;
        margin: 0 2.77rem;
        line-height: 50px;
    }

    .part4 .container .main-text p {

        margin: 0 5rem;
    }

    .part2 .img1,
    .part3 .img2,
    .part4 .img3 {

        height: 370px;
        width: 370px;
        background-size: cover;
        margin-top: 2rem;
    }

    .part2 .main-text h1 {

        font-size: 70px;
    }

    .part3 .img2 {

        margin-bottom: 2rem;
    }

    .part4 h1 {

        margin-top: 4rem;
        font-size: 50px;
    }

    .part5 .dropbtn {

        width: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        font-size: 45px;

    }

    .part5 .dropdown-content a {

        font-size: 30px;

    }
}

/* ***************************************************************************** */

@media(max-width:735px) {





    .part2 .main-text p,
    .part3 .main-text p,
    .part4 .main-text p {
        text-align: justify;
        font-size: 30px;
        margin: 0 1.77rem;
        line-height: 45px;
    }

    .part2 .img1,
    .part3 .img2,
    .part4 .img3 {

        height: 250px;
        width: 250px;
        background-size: cover;
        margin-top: 2rem;
    }

    .part3 .img2 {

        /* background-color: rebeccapurple; */
        margin-bottom: -10rem;

    }

    .part2 .main-text h1 {

        font-size: 50px;
    }


    .part4 h1 {

        margin-top: 3rem;
        font-size: 35px;
    }

    .part4 {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* flex-wrap: wrap; */
    }

}



/* ************************************************************** */

@media(max-width:665px) {

    .part1 .main-text h1 {
        font-size: 70px;
    }

    .part1 .main-text h3 {

        font-size: 20px;
    }

    .part4 .container .main-text p {
        margin: 0 140px;
        /* background-color: red; */
    }


    .part2 .main-text p,
    .part3 .main-text p,
    .part4 .main-text p {
        text-align: justify;
        font-size: 20px;
        margin: 0 1.77rem;
        line-height: 35px;
    }

    .part2 .img1,
    .part3 .img2,
    .part4 .img3 {

        height: 150px;
        width: 150px;
        background-size: cover;
        margin-top: 1rem;
    }

    .part5 .dropbtn {

        width: 250px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        font-size: 25px;

    }

    .part5 .dropdown-content a {

        font-size: 17px;

    }

}