body{
    overflow-x: hidden;
}
.team-card3{
    flex-direction: column;
    height: 250px;
    box-shadow: 5px 5px 5px hsla(0, 0%, 0%, 0.1);
    padding: 20px;
    margin: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 30px;
    max-width: 189px;
    display: flex;
    border: 2px solid #AF7800;
    background-color:#FBBC047A;
}
.team-card4{
    flex-direction: column;
    height: 250px;
    box-shadow: 5px 5px 5px hsla(0, 0%, 0%, 0.1);
    padding: 20px;
    margin: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 30px;
    max-width: 189px;
    display: flex;
    border: 2px solid #013A10;
    background-color: #329A4E75;
}
.team-card1{
    flex-direction: column;
    height: 250px;
    box-shadow: 5px 5px 5px hsla(0, 0%, 0%, 0.1);
    padding: 20px;
    margin: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 30px;
    max-width: 189px;
    display: flex;
    border: 2px solid #FF0000;
    background-color: #D63F3378;
}
.team-card2{
    flex-direction: column;
    height: 250px;
    box-shadow: 5px 5px 5px hsla(0, 0%, 0%, 0.1);
    padding: 20px;
    margin: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 30px;
    max-width: 189px;
    display: flex;
    border: 2px solid #0019FB;
    background-color: #3E7BDF75;
}

.image{
    max-width: 90%;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
    margin-right: 5px;
  
}
.card-title{
    font-size: 13px;
    content: "";
    position: absolute;
    font-family: 'Roboto', sans-serif;
    top: 170px;
}
.position{
    font-size: 13px;
    content: "";
    position: absolute;
    font-family: 'Roboto', sans-serif;
    top: 185px;
}
.social-media-container{
    display: flex;
    justify-content: center;
    align-items: center;
}


.insta{
    height: 60px;
    width: 30px;
    margin-right: 10px;
    padding-top: 30px;

}
.linkedin{
    height: 60px;
    width: 30px;
    margin-right: 10px;
    padding-top: 30px;

}
.git{
    height: 60px;
    width: 30px;
    margin-right: 10px;
    padding-top: 30px;
}

.x{
    height: 60px;
    width: 30px;
    margin-right: 10px;
    padding-top: 30px;
}

/*row properties*/
.row2{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 260px 260px 260px 260px;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: "";
    top: 110%;
    left: 0;
}
.row3{
    display: grid;
    grid-template-columns: 360px 360px 360px 360px;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: "";
    top: 120%;

    left: 22%;
}
.drow3{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 260px 260px 260px 260px;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: "";
    top: 100%;
    left: 38%;
}
.crow2{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 260px 260px 260px 260px;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: "";
    top: 100%;
    left: 38%;
    
}
/*first part*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

.bg{
    background-image: url("../../assets/teamsimages/gdscbg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 4rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; 
}
.div{
    display: inline-block;
    
}

.team-header{
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 100px;
    -webkit-text-stroke: 2px black;
    margin:0;
    position: absolute;
    top: 20%; /* Vertically center the team header */
    left: 50%; /* Horizontally center the team header */
    transform: translate(-50%, -50%); /* Translate to center position */
    z-index: 1; 
}
.second-header{
    text-align: center;
    font-family: 'Roboto', sans-serif;
    color: white;
    -webkit-text-stroke: 1.02px rgba(118, 113, 113, 0.86);
    margin:0;
    position: absolute;
    top: 32%; /* Vertically center the team header */
    left: 51%; /* Horizontally center the team header */
    transform: translate(-50%, -50%); /* Translate to center position */
    z-index: 1; 
    font-size: 50px;
}
.team-header span{
    display: inline-block; /* Makes each letter a separate block */
    margin: 0 px 5px;
}
.circle{
    background-color: #EA4335;
    height: 105.8px;
    width: 114px;
    border-radius: 50%;
    margin: 10% auto;
    position: relative;
    margin-top: 40%;
    margin-left: 32%;

}
.circle2{
    background-color: #F9AB00;
    height: 105.8px;
    width: 114px;
    border-radius: 50%;
    margin: 10% auto;
    position: relative;
    top: -36vh;
    left: -9vh;
}

.circle3{
    background-color: #4285F4;
    height: 105.8px;
    width: 114px;
    border-radius: 50%;
    margin: 10% auto;
    position: relative;
    top: -72vh;
    left: 10vh;

}
.circle4{
    background-color: #34A853;
    height: 105.8px;
    width: 114px;
    border-radius: 50%;
    margin: 10% auto;
    position: relative;
    top: -108vh;
    left: 29vh;
}
.logo{
  content: "";
  position: absolute;
  top: 87vh;
  left: 68.5vh;
  width: 5%;
  height: 5%;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}
.learn{
  color: white;
  content: "";
  position: absolute;
  top: 88vh;
  font-size: 15px;
  font-family:'Roboto', sans-serif ;
  -webkit-text-stroke: 1px  black;
  left: 89.5vh;
}
.connect{
    color: white;
    content: "";
    position: absolute;
    top: 88vh;
    font-size: 15px;
    font-family:'Roboto', sans-serif ;
    -webkit-text-stroke: 1px  black;
    left: 52.5%;
}
.grow{
    color: white;
    content: "";
    position: absolute;
    top: 88vh;
    font-size: 15px;
    font-family:'Roboto', sans-serif ;
    -webkit-text-stroke: 1px  black;
    left:62.5%;
}
.bg1{
    content: "";
    position: absolute;
    top: 87vh;
    left: 1px;
    
}
.bg2{
    position: absolute;
    content: "";
    top: 87vh;
    left: 75%;

}
.bg3{
    content: "";
    position: absolute;
    top: 270%;
    left: 1px;
}
.bg4{
    content: "";
    position: absolute;
    top: 270%;
    left: 77%;
}
.bg5{
   content: "";
   position: absolute;
   top: 447%; 
   left: 1px;
}
.bg6{
    content: "";
    position: absolute;
    top: 447%; 
    left: 166vh;
}
.bg7{
   position: absolute;
   content: "";
   top: 650%;
   left: 1px;
}
.bg8{
    position: absolute;
    content: "";
    top: 620%;
    left: 182.2vh;
}
.bg9{
    position: absolute;
    content: "";
    top: 753%;
    left: 1px;

}
.bg10{
    position: absolute;
    content: "";
    top: 850%;
    left: 142vh;
    
}
.core-team{
    position:absolute;
    content: "";
    color: #656C73;
    font-size: 69.998px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-shadow: 7px 7px 4px 0px #00000040 inset;
    -webkit-text-stroke: 1.5px black;
    text-align: center;
    font-family: 'Source sans 3', sans-serif;
    top: 105%;
    left: 33%;

    
}
.core-team-desc{
    position:absolute;
    content: "";
    color: #656C73;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    top: 124%;
    left: 27%;
    font-weight: 700;
   

}
.core-team-container{
    display: grid;
    grid-template-columns: 260px 260px 260px 260px;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: "";
    top: 155%;
    left: 12%;
}

.technical-team{
    position:absolute;
    content: "";
    color: #FF6060EB;
    font-size: 69.998px;
    font-style: normal;
    font-weight: 900;
    -webkit-text-stroke: 1.5px black;
    text-align: center;
    font-family: 'Source sans 3', sans-serif;
    top: 310%;
    left: 23%;
}
.technical-team-desc{
    position:absolute;
    content: "";
    color: #656C73;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    font-weight: 700;
    top: 330%;
    left: 21%;

}
.technical-team-container{
    display: grid;
    grid-template-columns: 260px 260px 260px 260px;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: "";
    top: 350%;
    left: 14%;


}
.design-team{
    position:absolute;
    content: "";
    color: #FBBC04;
    font-size: 69.998px;
    font-style: normal;
    font-weight: 900;
    -webkit-text-stroke: 1.5px black;
    text-align: center;
    font-family: 'Source sans 3', sans-serif;
    top: 485%;
    left: 31%;

}
.design-team-desc{
    position:absolute;
    content: "";
    color: #656C73;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    font-weight: 700;
    top: 505%;
    left: 19%;

}
.design-team-container{
    display: grid;
    grid-template-columns: 260px 260px 260px 260px;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: "";
    top: 530%;
    left: 14%;
}

.content-team{
    position:absolute;
    content: "";
    font-size: 60px;
    color: #4285F4D9;
    font-style: normal;
    font-weight: 900;
    -webkit-text-stroke: 1.5px black;
    text-align: center;
    font-family: 'Source sans 3', sans-serif;
    top: 660%;
    left: 30%;
}
.content-team-desc{
    position:absolute;
    content: "";
    color: #656C73;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    font-weight: 700;
    top: 675%;
    left: 33%;
}
.content-team-container{
    display: grid;
    grid-template-columns: 260px 260px 260px 260px;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: "";
    position: absolute;
    content: "";
    top: 690%;
    left: 13%;
}
.management-team{
    position:absolute;
    content: "";
    color: #34A853B0;
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    -webkit-text-stroke: 1.5px black;
    text-align: center;
    font-family: 'Source sans 3', sans-serif;
    top: 745vh;
    left: 25%;
}
.management-team-desc{
    position:absolute;
    content: "";
    color: #656C73;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    font-weight: 700;
    top: 762vh;
    left: 21%;

}
.management-team-container{
    display: grid;
    grid-template-columns: 260px 260px 260px 260px;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: "";
    position: absolute;
    content: "";
    top: 780vh;
    left: 32%;

}
.marketing-team{
    position:absolute;
    content: "";
    color: #FF00009C;
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    -webkit-text-stroke: 1.5px black;
    text-align: center;
    font-family: 'Source sans 3', sans-serif;
    top: 830vh;
    left: 28%;

}
.marketing-team-desc{
    position:absolute;
    content: "";
    color: #656C73;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    font-weight: 700;
    top: 850vh;
    left: 22%;
}
.marketing-team-container{
    display: grid;
    grid-template-columns: 260px 260px 260px 260px;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: "";
    position: absolute;
    content: "";
    top: 865vh;
    left: 32%;

}